<template>
  <div class="stuContact A4Container flexCloumnCenter pageBreak_no" style="">
    <!-- <div class="trans1"> -->
    <div class="form">
      <div class="table" style="height: 100%">
        <el-image :src="info.zf_url02" alt="" style="width:100%;height:100%;" fit="contain" />
      </div>
    </div>
    <!-- </div> -->
  </div>
</template>

<script>

export default {
  name: 'roll',
  data() {
    return {
      dataover: false,
      userid: ''
    }
  },
  props: {
    info: {
      type: Object,
      default: {}
    },
    idx: {
      type: Number,
      default: 1
    },
    xunfangsp: {
      type: Number,
      default: 1
    },
    schoolname: {
      type: String,
      default: ''
    },
  },
  mounted() {
    let _this = this
    if (_this.info.username) {
      _this.$nextTick(() => {
        // _this.resizeA()
        // _this.resizeB()
      })
    }
    if (_this.info.audit_remark) {
      _this.$nextTick(() => {
        // _this.resizeC()
      })
    }

  },
  methods: {
    resizeA() {
      let id1 = `divCntnrA_${this.idx}`;
      let id2 = `resizeDivA_${this.idx}`;

      let divHeight = document.getElementById(id1).offsetHeight;
      let textHeight = document.getElementById(id2).offsetHeight;
      let i = 1;
      console.log(124, "a", textHeight, divHeight, i);
      while (textHeight > divHeight && i < 7) {
        // 获取所有带有指定类名的元素
        var elements = document.getElementsByClassName("resizeDivA_div");
        // 遍历所有获取到的元素，并且设置fontSize
        for (var idx = 0; idx < elements.length; idx++) {
          // 检查元素是否是一个div，因为getElementsByClassName返回的是所有具有指定类名的元素
          if (elements[idx].tagName.toLowerCase() === "div") {
            elements[idx].style.fontSize = `${18 - i}px`; // 设置字体大小为16px
          }
        }
        // document.getElementById(id2).style.fontSize = `${18 - i}px`;
        textHeight = document.getElementById(id2).offsetHeight;
        i++;
      }
    },
    resizeA1() {
      let divHeight = document.getElementById(`divCntnrA_${this.idx}`).offsetHeight
      let textHeight = document.getElementById(`resizeDivA_${this.idx}`).offsetHeight
      // console.log('a', divHeight, textHeight)
      let i = 1
      while (textHeight > divHeight && i < 5) {
        document.getElementById(`resizeDivA_${this.idx}`).style.fontSize = `${14 - i}px`
        textHeight = document.getElementById(`resizeDivA_${this.idx}`).offsetHeight
        i++
      }
    },
    resizeB() {
      let divHeight = document.getElementById(`divCntnrB_${this.idx}`).offsetHeight
      let textHeight = document.getElementById(`resizeDivB_${this.idx}`).offsetHeight
      // console.log('b', this.idx, divHeight, textHeight)
      let i = 1
      while ((textHeight > divHeight) && i < 5) {
        document.getElementById(`resizeDivB_${this.idx}`).style.fontSize = `${14 - i}px`
        textHeight = document.getElementById(`resizeDivB_${this.idx}`).offsetHeight
        i++
      }
    },

    resizeC() {
      let divHeight = document.getElementById(`divCntnrC_${this.idx}`).offsetHeight
      let textHeight = document.getElementById(`resizeDivC_${this.idx}`).offsetHeight
      console.log('C', this.idx, divHeight, textHeight)
      let i = 1
      while ((textHeight > divHeight) && i < 5) {
        document.getElementById(`resizeDivC_${this.idx}`).style.fontSize = `${14 - i}px`
        textHeight = document.getElementById(`resizeDivC_${this.idx}`).offsetHeight
        i++
      }
    },

  }
}
</script>

<style lang="less" type="text/less">
.stuRolls .ant-col {
  display: flex;
}
</style>
<style lang="less" type="text/less" scoped>
.stuContact {
  font-size: 20px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 25px 0;

  .title {
    font-size: 20px;
    line-height: 50px;
  }

  .form {
    width: calc(100% - 50px);
    height: calc(100% - 50px);
    overflow: hidden;

    div {
      font-size: 14px;
      color: #323232;
      box-sizing: border-box;
    }

    .content {
      width: 100%;
      height: 100%;
      padding: 10px;
      white-space: break-spaces;
      word-break: break-all;
      overflow: hidden;
    }

    .el-row1 {
      border-top: 1px solid #000;
    }
  }

  .col-row {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}
</style>
