<template>
    <div style="margin: 0 auto;">
        <div class="pdfok" id="pdfok" v-if="pdfok"></div>
        <div id="printMe" style=" width: 21cm; margin: 0 auto;border: 0;">
            <div class="" v-for="(item, index) in vstList" :key="index">
                <InnerPage class="A4Container" :info="item" :idx="index" :xunfangsp="xunfang_sp"
                    :schoolname="schoolname"></InnerPage>
                <InnerPage2 v-if="item.zf_url02" class="A4Container" :info="item" :idx="index" :xunfangsp="xunfang_sp"
                    :schoolname="schoolname"></InnerPage2>
            </div>
        </div>
        <el-button v-if="false" v-print="'#printMe'" type="primary"
            style="position: fixed;top: 0;left: 0;z-index: 999;">打印</el-button>

    </div>
</template>

<script>

import InnerPage from './com_tch_vst/innerPage_img'
import InnerPage2 from './com_tch_vst/innerPage_img_02'


export default {
    name: 'export',
    components: {
        InnerPage, InnerPage2
    },
    data() {
        return {
            pdfok: false,
            show_dev: true,
            show_dev1: true,
            printObj: {
                id: 'printMe',
                popTitle: '',
                extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
            },
            userid: '',
            cover: {},
            rolls: [],
            stu_kaohe: [],
            stu_lianxi: [],
            shixi_anli: [{}],
            youxiu_shixisheng: [],
            stu_weiji: [],
            fanxiao: [],
            dataInfo: {},
            vstList: [],
            xunfang_sp: 1,//是否开启巡访审核 控制签字字段
            schoolname: ''
        }
    },
    mounted() {

        let class_id = this.getParams('class_id')
        let ym = this.getParams('ym')
        let year = ''
        let month = ''
        if (ym && ym.indexOf('-') > -1) {
            let arr = ym.split('-')
            year = arr[0]
            month = arr[1]
        }
        let teacher_id = this.getParams('id')
        let pmid = this.getParams('pmid')
        let dataid = this.getParams('dataid')
        this.getConfig(pmid)
        this.getSchool(pmid)

        this.getData(teacher_id, pmid, dataid)
        // this.getAllPickerVal(pmid).then(rr => {
        //   this.getDataList(class_id, year, month, teacher_id, pmid, rr, dataid)
        // })
    },
    methods: {
        //是否开启巡访审核
        getConfig(pmid) {
            this.$http.post("/api/sch_config_one", { ktype: "xunfang_sp", pmid: pmid }).then(res => {
                if (res.data) {
                    this.xunfang_sp = Number(res.data.kvalue)
                }
            })
        },
        getSchool(pmid) {
            this.$http.post("/api/sch_config_one", { ktype: "schoolname", pmid: pmid }).then(res => {
                if (res.data) {
                    this.schoolname = res.data.kvalue
                }
            })
        },
        getAllPickerVal(pmid) {
            return new Promise(resolve => {
                let arrForm = [], arrState = [];
                //巡访形式&&巡访情况
                this.$http.post("/api/sys_param_list", { param_type: "'VISIT_FORM','VISIT_SITUATION'", pmid: pmid }).then((res) => {
                    res.data && res.data.map((item, index, arr) => {
                        if (item.param_type == 'VISIT_FORM') {
                            arrForm.push(item)
                        }
                        if (item.param_type == 'VISIT_SITUATION') {
                            arrState.push(item)
                        }
                    })
                    resolve({
                        arrForm, arrState,
                    })
                })
            })
        },

        //过滤特殊字符, 校验所有输入域是否含有特殊符号
        stripscript(s) {
            var mdash = '&mdash;'
            var nbsp = '&nbsp;'
            var quot = '&quot;'
            var ldquo = '&ldquo;'
            var rdquo = '&rdquo;'
            var amp = '&amp;'
            var lt = '&lt;'
            var gt = '&gt;'


            var rs = ''
            rs = s.replace(new RegExp(mdash, 'g'), '-');
            rs = rs.replace(new RegExp(nbsp, 'g'), '');
            rs = rs.replace(new RegExp(quot, 'g'), '"');
            rs = rs.replace(new RegExp(ldquo, 'g'), '”');
            rs = rs.replace(new RegExp(rdquo, 'g'), '“');
            rs = rs.replace(new RegExp(amp, 'g'), '&');
            rs = rs.replace(new RegExp(lt, 'g'), '<');
            rs = rs.replace(new RegExp(gt, 'g'), '>');
            return rs
        },
        getDataList(class_id, year, month, teacher_id, pmid, rr, dataid) {
            let _this = this
            let arrForm = rr ? rr.arrForm : []
            let arrState = rr ? rr.arrState : []
            this.$http.post('/api/initVisitDataPdf', {
                class_id: parseInt(class_id),
                year: year || '',
                month: month || '',
                teacher_id: parseInt(teacher_id),
                pmid: parseInt(pmid),
                dataid: dataid ? parseInt(dataid) : ''
            }).then(res => {
                if (res.data) {
                    let rData = res.data
                    console.log(rData)
                    if (rData && rData.length > 0) {
                        let arr = []
                        rData.map(a => {
                            if (a.fieldname) {
                                try {
                                    a.fieldname = JSON.parse(a.fieldname)
                                    a.fieldname.map((option, i) => {
                                        if (i != 0) {
                                            if (option.fileUrl) option.fileUrl += `?x-oss-process=image/resize,m_fill,h_150,w_110`;
                                            if (option.src) option.src += `?x-oss-process=image/resize,m_fill,h_150,w_110`;
                                        }
                                    })
                                } catch { console.log('error:fieldname') }

                            } else {
                                a.fieldname = []
                            }
                            if (a.sign_url) {
                                // a.sign_url += `?x-oss-process=image/resize,m_fill,h_150,w_110`
                            }
                            arr.push(a)
                        })
                        this.vstList = arr

                    }
                }
                this.$nextTick(() => {
                    setTimeout(() => { _this.pdfok = true }, 500)
                })
            })
        },
        getData(tchr_id, pmid, dataid) {
            let _this = this;
            this.$http.post("/api/init_teacher_zf_info", { pmid: pmid, id: dataid }).then((res) => {
                let info = res && res.data ? JSON.parse(JSON.stringify(res.data)) : {};
                if (info.visit_summary) {
                    let arr = info.visit_summary.split("\n");
                    arr = arr.filter(function (s) {
                        return s && s.trim();
                    });
                    info.contentArr = arr;
                    console.log("arrarrarr", arr);
                }
                if (info.stu_list && this.isJSON(info.stu_list)) {
                    let stu_list = JSON.parse(info.stu_list);
                    if (stu_list && stu_list.length > 0) {
                        info.stuInfo = stu_list[0];
                    }
                }
                if (info.visit_date) {
                    info.visit_date = info.visit_date.substring(0, 10);
                }
                if (info.fieldname) {
                    try {
                        info.fieldname = JSON.parse(info.fieldname)
                        info.fieldname.map((option, i) => {
                            if (i != 0) {
                                if (option.fileUrl) option.fileUrl += `?x-oss-process=image/resize,m_fill,h_150,w_110`;
                                if (option.src) option.src += `?x-oss-process=image/resize,m_fill,h_150,w_110`;
                            }
                        })
                        if (info.fieldname && info.fieldname.length > 0) {
                            info.zf_url02 = info.fieldname[0].fileUrl || info.fieldname[0].src
                        }
                    } catch { console.log('error:fieldname') }

                } 
                this.vstList = [info]

                this.$nextTick(() => {
                    setTimeout(() => { _this.pdfok = true }, 500)
                })
            });
        },
        getParams(name) {
            let n_url = window.location.href
            n_url = n_url.replace('/#', '')
            if (n_url.indexOf('?') > 0) {
                n_url = n_url.split('?')[1]
            }
            var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
            var r = n_url.match(reg)
            if (r != null) return unescape(r[2])
            return null
        },
        //分割数组 arr：原数组  num：分割后每个数组里的数据条数
        util_spliceArrByN(arr, num) {
            var _arr = []
            while (arr.length) {
                _arr.push(arr.splice(0, num))
            }
            return _arr
        },
        //数组按某属性分组
        groupBy(array, f) {
            let groups = {}
            array.forEach(function (o) {
                let group = JSON.stringify(f(o))
                groups[group] = groups[group] || []
                groups[group].push(o)
            })
            return Object.keys(groups).map(function (group) {
                return groups[group]
            })
        }
    }
}
</script>
<style lang="less" type="text/less">
@import "/public/css/pdf_default.less";

body {
    margin: 0;
}
</style>
